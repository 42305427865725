// 引入加强后的axios也就是instance
import instance from '../../../untils/request.js'


export function contentList(params){
    return instance({
        method: 'get',
        url: '/api/base/other/content/newsManagerList',
        params: params,
    })
} 

export function customCaseList(params){
    return instance({
        method: 'get',
        url: '/api/base/other/content/customCaseList',
        params: params,
    })
} 