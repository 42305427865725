<template>
  <div class="pox">
    <!-- <div class="pox_box">
      <div class="box_left">
        <div class="left_box">
          <div class="left_box_tit">
            <p class="box_tit_title">
              {{ centitdata.title }}
            </p>
            <p class="box_tit_jx">{{ centitdata.tittime }}</p>
          </div>

          

          <div class="left_box_cen" v-if="bty == 1">
            <p class="leftcen_p1">
              河南羿赐智能科技有限公司董事长张亚朝先生，接受OpenNE地热能集成大会官方采访！新品发
              布！河南羿赐智能科技有限公司推出暖通智慧能源管理系统！
            </p>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq2.jpeg" alt="" />
            </div>
          </div>
          <div class="left_box_cen" v-if="bty == 2">
            <p class="leftcen_p1">
              2024年1月12日，以“开放共赢
              拥抱地热新时代”为主题的OpenNE·2024地热能系统集成大会在北京·湖南大厦成功召开。大会同期举办OPENNE地热青年论坛、OPENNE
              地热论剑、OPENNE地热智控新品发布会、OPENNE
              地热能技术与装备室内展。OpenNE大会（ Open New Energy
              大会，简称OpenNE大会，中文名为：欧朋纳大会）大会采用专题报告、技术研讨、新产品发布和展示、商务洽谈、采购交易等活动形式，旨在聚焦地热全产业链，搭建央企、国企与民企、投融资机构互利合作、设备材料生产厂商与系统集成商之间交流、互动、交易平台。
            </p>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq1.jpeg" alt="" />
            </div>
            <p class="leftcen_p1">
              我司有幸受邀参加OPENNE地热能系统集成大会。这次聚集了业内众多专家和企业的盛会，旨在探讨和推动地热能源领域的创新与发展。
            </p>
            <p class="leftcen_p1">
              作为一家致力于智能控制技术研究和应用的企业，我们非常重视可持续能源的开发和利用。地热能作为一种清洁、可再生的能源，对环境友好且具有巨大潜力。然而，在地热能系统的集成和管理过程中，仍存在一系列挑战和机遇。
            </p>
            <p class="leftcen_p1">
              本次大会提供了一个交流和学习的平台，将有机会与行业内的专家、学者和企业家们共同探讨地热能系统的集成和管理最佳实践。我们期待通过这次大会，与各位一起分享经验、展示技术，共同推动地热能系统的创新和发展。
            </p>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq3.jpeg" alt="" />
            </div>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq4.jpeg" alt="" />
            </div>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq5.jpeg" alt="" />
            </div>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq6.jpeg" alt="" />
            </div>
            <p class="leftcen_p1">
              我们在主题报告环节，董事长张亚朝先生、《智能控制技术在地热能源站系统中的应用案例》、的精彩报告。
            </p>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq2.jpeg" alt="" />
            </div>
            <p class="leftcen_p1">
              分别通过新能源、节能、数字化以及智慧控制分别表达了自己对智能控制技术在地热能源站系统中的应用，给出了我们自己的理解。将分享我们在智能控制技术方面的最新研究成果和应用案例。通过智能控制技术，可以实现精确的温度控制，最大限度地优化能耗，实时预警和诊断设备故障，并实现远程监控和管理。我们也介绍我们采用数字化和智慧控制等技术，实现末端能耗的精准把控，提高能源利用效率，降低能耗，提高系统的可靠性和运行稳定性的经验。
            </p>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq7.jpeg" alt="" />
            </div>
            <p class="leftcen_p1">
              会议期间，我司举行了“暖通智慧能源管理系统新品发布会”，获得与会嘉宾广泛关注和一致好评。河南羿赐智能科技有限公司以中央空调暖通系统节能自控为核心技术，为建筑和工业节能和能源管控提供解决方案。公司推出暖通专业的大屏可视化编辑平台，以及积木式全中文策略控制编辑软件，不借助专业的PLC编程工程师，暖通设计人员自己就可以完成暖通空调项目的数据可视化平台建设和智慧能源管理系统搭建，大大降低数字化暖通系统的建设费用，为智慧暖通系统的普和应用开辟了新的途径。
            </p>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq8.jpeg" alt="" />
            </div>
            <p class="leftcen_p1">
              我司本次会议中荣幸的获得了OPENNE大会优秀技术奖。
            </p>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq9.jpeg" alt="" />
            </div>
            <p class="leftcen_p1">
              中国技术监督情报协会地热产业工作委员会主任陈焰华、中国地质调查局浅层地温能研究与推广中心主任李宁波和北京中航环宇新能源研究院院长陈伯亚分别为获奖者颁奖。最后，中国技术监督情报协会地热产业工作委员会主任陈焰华为本次大会作精彩总结。
            </p>
            <p class="leftcen_p1">
              我们对能够参加这次会议感到非常荣幸。这次会议将为我们提供一个交流与学习的平台，让我们能够与各界专家和同行分享我们的经验和见解。我们相信通过这次会议，我们将能够获得更多关于智能能源控制技术的最新发展和应用案例，从而进一步提升我们的专业知识和能力。同时，我们也期待能够结识更多志同道合的人，建立起广泛的合作网络，共同推动智能能源控制领域的发展。
            </p>
          </div>
          <div class="left_box_cen" v-if="bty == 3">
            <p class="leftcen_p1">
              大会同期举办：OPENNE 地热青年论坛、OPENNE 地热圆桌论剑、OPENNE
              地热智控新品发布会、OPENNE 地热能技术与装备室内展
            </p>
            <div class="img1"  style="height:27.60417vw">
              <img src="../../../assets/imgs/xw/xwxq10.png" alt="" />
            </div>
            <p class="leftcen_p1">
              记者获悉，由中国技术监督情报协会地热产业工作委员会、北京中航环宇新能源研究院联合主办，《中国地热》杂志、地热加
              APP、地热能网、北京碳主科技有限公司联合承办的“OpenNE·2024地热能系统集成大会”定于2024年1月12日在北京·湖南大厦召开。大会同期将举办OPENNE地热青年论坛、OPENNE
              地热圆桌论剑、OPENNE地热智控新品发布会、OPENNE
              地热能技术与装备室内展。
            </p>
            <p class="leftcen_p1">
              本次大会以“开放共赢
              拥抱地热新时代”主题，采用专题报告、技术研讨、新产品发布和展示、商务洽谈、采购交易等活动形式。大会旨在搭建央企、国企与民企、投融资机构互利合作、设备材料生产厂商与系统集成商之间交流、互动、交易平台。OpenNE大会是免费开放的大会，所有个人报名参加本次OpenNE
              地热能系统集成大会均不收取任何参会费。
            </p>
            <div class="img1">
              <img src="../../../assets/imgs/xw/xwxq11.png" alt="" />
            </div>
            <p class="leftcen_p1">
              值得一提的是，河南羿赐智能科技有限公司将在本届大会上发布地热智控新品。河南羿赐智能科技有限公司成立于2022年，致力于打造智慧能源管控系统PaaS平台，以中央空调暖通系统节能自控为核心技术，为建筑和工业节能和能源管控提供解决方案。而这次即将发布的地热智控新品无疑是河南羿赐智能科技有限公司创新实力的一次全新展现。河南羿赐智能科技有限公司业务涵盖建筑能耗计量监控分析、工业能耗计量监控分析、中央空调智慧管控系统、热泵以及各种换热设备的设计安装、节能自控、运行维护，是集设计、销售、安装、维修服务为一体的技术企业。公司推出暖通专业的大屏可视化编辑平台，以及积木式全中文策略控制编辑软件，不借助专业的PLC编程工程师，暖通设计人员自己就可以完成暖通空调项目的数据可视化平台建设和智慧能源管理系统搭建，大大降低数字化暖通系统的建设费用，为智慧暖通系统的普和应用开辟了新的途径。新产品新技术发布作为本次大会重要活动之一，将引起行业内外的瞩目。河南羿赐智能科技有限公司此次发布的地热智控新品具有哪些特点、核心技术是怎样的？大会现场将为您揭晓。面对未来，我们有理由期待河南羿赐智能科技有限公司能够继续保持其创新的步伐，为消费者带来更多独特和优质的产品及服务。届时，大会将邀请央企、国企、事业单位代表，投融资机构嘉宾代表，地热能、地水源热泵、空气源热泵、地热+光伏、地热+风能、地热+生物质能、地热+储能系统集成商、运营服务商和方案解决商，以及地热能行业主流生产制造设备企业、配套服务产品、经销代理、施工安装及系统集成企业嘉宾代表等出席大会。
            </p>
            <p class="leftcen_p2">
              关于召开 OpenNE · 2024 地热能系统集成大会的通知
            </p>
            <p class="leftcen_p3">各相关单位：</p>
            <p class="leftcen_p1" style="margin-top: 0.625vw">
              随着“双碳”目标深入推进和各省市新能源产业支持力度的加大，中国地热产业迎来了新一轮的战略机遇期，正不断向大规模、高比例、市场化、高质量方向发展。创新奋进，争先破局。在新的发展制高点，地热能、地水源热泵、空气源热泵、太阳能光伏、储热储冷等新能源企业该如何破局？降本增效，开放共赢。后疫情时代，“地热+”产业链该如何协同配套和优化升级，实现更加健康可持续的高质量发展？
            </p>
            <p class="leftcen_p1" style="margin-top: 0.625vw">
              开拓创新，拥抱地热新时代。为整合优化地热行业创新产品、技术、投融资机构、产业园区和终端用能单位等相关优质资源，搭建央企、国企与民企、投融资机构互利合作、设备材料生产厂商与系统集成商之间交流、互动、交易平台，由中国技术监督情报协会地热产业工作委员会、北京中航环宇新能源研究院联合主办，《中国地热》、地热加APP、地热能网等联合承办的“OpenNE
              2024地热能系统集成大会”定于2024年1月12日在北京召开。OpenNE
              大会旨在通过免费开放的方式公开分享：地热能、地水源热泵、空气源热泵、地热+光伏、地热+生物质能、地热+风能、地热+储热储冷等新能源行业先进的创新技术、营销经验、施工工艺、经营管理、投资融资、开发盈利模式等，推动中国“地热+”产业向广度深度拓展。大会同期将举办OpenNE地热青年论坛、颁发OpenNE优秀讲演奖、OpenNE创新奖等活动。现将相关事宜通知如下：
            </p>
            <p class="leftcen_p3" style="color: red">一、大会主题</p>
            <p class="leftcen_p4">开放共赢 拥抱地热新时代</p>
            <p class="leftcen_p3" style="color: red">二、大会组织</p>
            <p class="leftcen_p4">
              （一）指导单位：中国技术监督情报协会中国地质调查局浅层地温能研究与推广中心
            </p>
            <p class="leftcen_p4">
              （二）主办单位：中国技术监督情报协会地热产业工作委员会北京中航环宇新能源研究院
            </p>
            <p class="leftcen_p4">
              （三）承办单位：《中国地热》杂志、地热加APP、地热能网、北京碳主科技有限公司
            </p>
            <p class="leftcen_p4">
              （四）支持单位：自然资源部浅层地热能重点实验室、自然资源部深部地热资源重点实验室、自然资源部地热与干热岩勘查开发技术创新中心、中石化绿源地热能开发有限公司、中能建地热有限公司、中石油深圳新能源研究院有限公司、天津地热勘查开发设计院、山西省煤炭地质水文勘查研究院有限公司、西安煤科地热能开发有限公司、中国石油长庆油田分公司、浙江陆特能源科技股份有限公司、北京金茂人居环境科技有限公司、自然资源部第一海洋研究所海洋工程研究院、山东海利丰智慧能源科技有限公司、山西双良再生能源产业集团有限公司、山东省地质矿产勘查开发局第二水文地质工程地质大队（山东省鲁北地质工程勘察院）、山东省鲁南地质工程勘察院、四川省地质工程勘察院
              集团有限公司、四川省地热资源开采利用工程研究中心、河北省地质矿产勘查开发局第一地质大队地热调查所等。
            </p>
            <p class="leftcen_p3" style="color: red">三、主要内容</p>
            <p class="leftcen_p4">
              大会主要采用专题报告、技术研讨、新产品发布和展示、商务洽谈、采购交易等活动形式。围绕地热能、地水源热泵、空气源热泵、太阳能光伏、风能、生物质能、储热储冷等产业链上下游产品与技术，商业推广模式、地热+多能互补应用等议题展开探讨和交流。会议期间的展览和展示将为参会代表和专业观众展示地热能、地水源热泵、空气源热泵、地热+太阳能光伏、地热+风能、地热+生物质能、地热+储能等产品和技术解决方案。大会嘉宾报告主题方向：
            </p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">
              1、地热能+新能源多能互补设计思路与案例2、储热、储冷等储能技术在高效地热能系统中的应用3、废井煤矿与油井地热能开发与利用4、高效中深层地埋管地源热泵机组与高温水源热泵技术5、空气源热泵+地水源热泵多能互补智慧能源系统6、暖通空调智控产品、人工智能技术在地热中的应用7、太阳能光伏、生物质能、储能+地热能利用典型案例分享8、地热高效钻井装备的开发与施工完井工艺9、智慧无人值守地热大数据平台的开发与应用10、地热卤水提“锂”技术与应用11、梯级利用与水热型地热高质量开发利用12、地热尾水回灌关键技术与装备开发应用13、地热在康养、设施农业、养殖种植、谷物干燥中的利用14、高、中、低温地热发电装备技术与应用15、地热区域能源站系统的规划设计、施工安装与运维管理16、地热+综合能源系统常用投融资模式对比分析17、央企、国企与地热民企合作优秀案例分享18、温室气体减排与地热碳资产的开发、管理、交易
            </p>
            <p class="leftcen_p3" style="color: red">四、参会嘉宾</p>
            <p class="leftcen_p4">央企、国企、事业单位代表、投融资机构嘉宾代表、地热能、地水源热泵、空气源热泵、地热+光伏、地热+风能、地热+生物质能、地热+储能系统集成商、运营服务商和方案解决商，以及地热能行业主流生产制造设备企业、配套服务产品、经销代理、施工安装及系统集成企业嘉宾代表等。</p>
            <p class="leftcen_p3" style="color: red">五、时间地点</p>
            <p class="leftcen_p4">时间：2024年1月12日（1月11日下午报到）地点：北京·湖南大厦</p>
            <p class="leftcen_p3" style="color: red">六、注意事项</p>
            <p class="leftcen_p4">1、OpenNE 欧朋纳大会是免费开放的大会，所有个人报名参加本次OpenNE 地热能系统集成大会均不收取任何参会费。2、组委会不安排接站。参会企业和代表可提前预订酒店住宿，食宿自理。3、企业如需现场展览，作主题报告或赞助宣传请与大会组委会联系。4、参会企业代表可以参加当天厂家提供的现场折扣采购。5、因场地限制，免费参加OpenNE 大会请提前报名，凭报到参会证免费入场参会，谢绝空降。</p>
            <p class="leftcen_p3" style="color: red">七、组委会联系方式</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">地址：北京朝阳区东三环中路39号</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">电话：010-64656526</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">陈老师 13331132168</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">展老师 18911085322</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">戴老师 13426331122</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">郑老师 13366222332</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">莫老师 18515158288</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">袁老师 13910809422</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">苏老师 15911004253</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">席老师 13261026938</p>
            <p class="leftcen_p4" style="margin-top:1.04167vw">网 址：www.direjia.com</p>
          </div>
          
        </div>
      </div>
      <div class="box_rig">
        <div class="rig_cen">
          <div class="cen_to">
            <div class="rig_title">
              <div class="tit_cen">
                <div class="tit_ic"></div>
                <div class="tit_size">其他新闻</div>
              </div>
            </div>
            <div class="rig_data">
              <ul v-for="(item, index) in listnameArr" :key="index">
                <li
                  :class="[item.codenum == bty ? 'green' : '']"
                  @click="handelNewcik(item.codenum)"
                >
                  {{ item.data }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  // props: {
  //   keynew: {},
  // },
  data() {
    return {
      dataArr: {
        id: 1,
        contentone:
          "河南羿赐智能科技有限公司董事长张亚朝先生，接受OpenNE地热能集成大会官方采访！新品发布！河南羿赐智能科技有限公司推出暖通智慧能源管理系统！",
        // imgurlone: require("@/assets/imgs/ycxw1.png"),
        // imgurlone: "",
      },
      datalistArr: [
        {
          id: 1,
          contentone:
            "河南羿赐智能科技有限公司董事长张亚朝先生，接受OpenNE地热能集成大会官方采访！新品发布！河南羿赐智能科技有限公司推出暖通智慧能源管理系统！",
          // imgurlone: require("@/assets/imgs/xw/xwxq2.jpeg"),
        },
        {
          id: 2,
          contentone:
            "2024年1月12日，以“开放共赢 拥抱地热新时代”为主题的OpenNE·2024地热能系统集成大会在北京·湖南大厦成功召开。大会同期举办OPENNE地热青年论坛、OPENNE 地热论剑、OPENNE地热智控新品发布会、OPENNE 地热能技术与装备室内展。OpenNE大会（ Open New Energy 大会，简称OpenNE大会，中文名为：欧朋纳大会）大会采用专题报告、技术研讨、新产品发布和展示、商务洽谈、采购交易等活动形式，旨在聚焦地热全产业链，搭建央企、国企与民企、投融资机构互利合作、设备材料生产厂商与系统集成商之间交流、互动、交易平台。",
          // imgurlone: require("@/assets/imgs/xw/xwxq1.jpeg"),
        },
      ],

      listnameArr: [
        {
          codenum: 1,
          data: "羿赐智能科技董事长张亚朝先生接受OpenNE地热大会官方采访",
        },
        {
          codenum: 2,
          data: "地热智能化，能源未来的控制之梦",
        },
        {
          codenum: 3,
          data: "OpenNE2024地热能系统集成大会",
        },
        // {
        //   codenum: 4,
        //   data: "数字化中央空调系统",
        // },
        // {
        //   codenum: 5,
        //   data: "风盘排布3D模型展示",
        // },
        // {
        //   codenum: 6,
        //   data: "B户型风盘3D漫游动画",
        // },
      ],
      bty: 1,
      centitArr: [
        {
          val: 1,
          title: "羿赐智能科技董事长张亚朝先生接受OpenNE地热大会官方采访",
          tittime: "河南羿赐智能科技有限公司 2024-01-26 10:36",
        },
        {
          val: 2,
          title: "地热智能化，能源未来的控制之梦",
          tittime: "河南羿赐智能科技有限公司 2024-01-16 14:42",
        },
        {
          val: 3,
          title: "OpenNE2024地热能系统集成大会",
          tittime: "河南羿赐智能科技有限公司 2024-01-09 17:54",
        },
      ],
      centitdata: {
        // val: 1,
        // title: "羿赐智能科技董事长张亚朝先生接受OpenNE地热大会官方采访",
        // tittime: "河南羿赐智能科技有限公司 2024-01-26 10:36",
      },
    };
  },
  inject: ["reload"],
  // watch: {
  //   keynew: {
  //     handler(newss, old) {
  //       console.log(newss , 'ff')
  //       this.bty = newss
  //     },
  //     immediate: false,
  //   },
  // },
  // 组件初始化
  created() {
    var numk = localStorage.getItem("newkey");
    this.bty = numk;
    for (var i in this.datalistArr) {
      if (this.datalistArr[i].id == numk) {
        this.dataArr = this.datalistArr[i];
      }
    }
    for (var k in this.centitArr) {
      if (this.centitArr[k].val == numk) {
        this.centitdata = this.centitArr[k];
      }
    }
    //console.log(numk, "454");
  },
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {
    handelNewcik(type) {
      //console.log(type);
      this.bty = type;
      for (var i in this.datalistArr) {
        if (this.datalistArr[i].id == type) {
          this.dataArr = this.datalistArr[i];
        }
      }
      for (var k in this.centitArr) {
        if (this.centitArr[k].val == type) {
          this.centitdata = this.centitArr[k];
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.leftcen_p4 {
  width: 100%;
  // text-align: center;
  // margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #1b1e20;
}
.leftcen_p2 {
  width: 100%;
  text-align: center;
  margin-top: 24px;
  font-size: 36px;
  font-weight: 600;
  color: red;
}
.leftcen_p3 {
  width: 100%;
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #1b1e20;
  line-height: 24px;
  // border: 1px solid gold;
  margin-top: 22px;
  // text-indent: 32px;
}
.pox {
  width: 100%;
  height: 100%;
  .pox_box {
    width: 100%;
    height: 100%;
    display: flex;
    .box_left {
      width: 722px;
      height: 100%;
      background-color: #ffffff;
      .left_box {
        width: 674px;
        height: 100%;
        margin: 0 auto;
        //border: 1px solid gray;
        .left_box_tit {
          width: 100%;
          height: 104px;
          border-top: 0.5px solid transparent;
          border-bottom: 2px solid #e4e4e4;
          .box_tit_title {
            margin-top: 24px;
            width: 100%;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #1b1e20;
            line-height: 28px;
            // border: 1px solid gold;
          }
          .box_tit_jx {
            margin-top: 8px;
            width: 100%;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #b1b1b1;
            line-height: 20px;
          }
        }
        .left_box_cen::-webkit-scrollbar {
          display: none;
        }
        .left_box_cen {
          width: 100%;
          height: 446px;
          overflow: hidden;
          overflow-y: scroll;
          // border: 1px solid gold;
          .leftcen_p1 {
            width: 100%;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #1b1e20;
            line-height: 24px;
            // border: 1px solid gold;
            margin-top: 22px;
            text-indent: 32px;
          }
          .img1 {
            width: 100%;
            height: 430px;
            // border: 1px solid gold;
            margin-top: 24px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .box_rig {
      width: 298px;
      cursor: pointer;
      //border: 1px solid gold;
      margin-left: 20px;
      .rig_cen {
        width: 298px;
        margin: 0 auto;
        background-color: #ffffff;
        //border: 1px solid gold;
        .cen_to {
          width: 250px;
          //border: 1px solid gold;
          margin: 0 auto;
          .rig_title {
            width: 100%;
            height: 76px;
            border-top: 0.5px solid transparent;
            border-bottom: 2px solid #e4e4e4;
            .tit_cen {
              width: 80%;
              height: 28px;
              margin-top: 24px;
              display: flex;
              //   border: 1px solid gray;
              .tit_ic {
                width: 20px;
                height: 20px;
                margin-top: 4px;
                // border: 1px solid gray;
              }
              .tit_size {
                widows: 80px;
                height: 28px;
                font-size: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #1b1e20;
                line-height: 28px;
                margin-left: 8px;
              }
            }
          }
          .rig_data::-webkit-scrollbar {
            display: none;
          }
          .rig_data {
            width: 100%;
            max-height: 477px;
            overflow: hidden;
            overflow-y: scroll;
            //   border: 1px solid gold;
          }
          li:hover {
            color: #0072ff;
            text-decoration: underline;
          }
          .green {
            color: #0072ff;
            text-decoration: underline;
          }
          ul li {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            height: 50px;
            line-height: 50px;
            list-style: none;
            border-bottom: 1.5px solid #F5F5F5;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #3d485d;
          }
        }
      }
    }
  }
}
</style>
